$breakpoint-desktop: 900px;

h1 {
    color: white;
}

.meet-the-team-header {
    color: #564270;
    text-align: center;
}

.green-about-divider {
    border-color: var(--team-color);
    border-style: solid;
    height: 3px;
    margin: 2em 4em;
}

.header-container {
    position: relative;
    text-align: center;
    display: flex;
}

.personCard {
    display: block !important;
    text-align: center !important;
    padding-bottom: 10% !important;
}

.personName {
    font-size: 1.5em;
}

.personImage {
    border-radius: 15%;
}

.aboutUsContainer {
    display: grid;
    align-self: center;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    background-color: rgba(206, 195, 195, 80%);
    border-radius: 50px;
    max-width: 60%;
    padding: 40px;
    justify-content: center;
    margin: 20px auto;
    position: relative;
}

.teamMemberContainer {
    display: grid;
    align-self: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto auto;
}

.productDesignerContainer {
    display: grid;
    align-self: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto auto;
}

.softwareDevsContainer {
    display: grid;
    align-self: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto auto;
}

.nav {
    grid-area: navBar;
}

.culture {
    grid-area: culture;
}

.whatWeDo {
    grid-area: whatWeDo;
}

.columnFormat {
    text-align: center;
}

.banner {
    width: 100%;
    min-height: 15em;
    max-height: 20em;
}

.aboutHeader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.greenLine {
    background-color: var(--team-color);
    height: 10px;
    z-index: 15;
}

.headerFormat {
    text-align: left;
}

.teamHeader {
    grid-area: teamHeader;
}

.topImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    width: auto;
}

.roundedImage {
    border-radius: 50%;
    margin: 2em 2em 0.5em 2em;
}

.team-list {
    padding: 0;
    margin: 0;
}

@media (min-width: $breakpoint-desktop) {
    .aboutUsContainer {
        display: grid;
        align-self: center;
        grid-template-columns: 40% 60%;
        grid-template-rows: auto auto;
        background-color: rgba(206, 195, 195, 80%);
        border-radius: 50px;
        max-width: 60%;
        padding: 40px;
        justify-content: center;
        margin: 20px auto;
    }

    .green-about-divider {
        margin: 2em 8em;
    }

    .banner {
        height: 30em;
    }
}
