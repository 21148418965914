$breakpoint-desktop: 900px;

.header-container {
  position: relative;
  text-align: center;
  display: flex;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.green-divider {
  border-color: var(--team-color);
  border-style: solid;
  height: 3px;
  margin: 2em auto;
}

.header-image {
  width: 100%;
  min-height: 15em;
  max-height: 20em;
}

.fcg-body-container {
  margin: 2rem 3rem;
  position: relative;

  p {
    font-size: revert;
    line-height: 150%;
  }

  ul {
    font-size: revert;
    list-style: revert;
    line-height: 150%;
  }

  .fcg-list {
    list-style: initial;
    font-size: revert;
  }

  a {
    text-decoration: revert;
    color: revert;
    transition: revert;
    font-size: revert;
    word-wrap: break-word;
  }
}

.fcg-header {
  font-size: revert;
}

.fcg-footer-list {
  font-size: revert;
  text-align: left;
  list-style: revert;
}

.fcg-button-container {
  text-align: center;
  margin: 2em 0;

  border-radius: 0.5em;
  padding: 0.5em;
  background: var(--team-color);
  border: none;
  box-shadow: 4px 4px 6px #999;

  a {
    text-decoration: none;
    color: black;
  }
}

.fcg-button-container:hover {
  opacity: 0.8;
}

.fcg-button-container:active {
  background-color: #dbdbdb;
  box-shadow: 2px 2px 3px #666;
  transform: translateY(4px);
}

.flex-container {
  img {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    margin-top: 1em;
  }
}

.fcg-footer-contact-us {
  padding-right: 35px;
  margin: auto;
  border-style: solid;
  border-radius: 25px;
  text-align: center;
  border-color: var(--team-color);
  width: fit-content;
}

@media (min-width: $breakpoint-desktop) {
  .fcg-body-container {
    margin: 2rem 9rem;
  }

  .green-divider {
    margin: 2em 8em;
  }

  .flex-container {
    display: flex;

    .flex-child {
      flex: 1;
      margin-right: 5em;
    }

    .flex-child:last-child {
      margin-right: 0;
    }
  }

  .fcg-button-container {
    border-radius: revert;
    padding: revert;
    background: revert;
    box-shadow: revert;

    a {
      border-radius: 0.5em;
      padding: 0.5em;
      background: var(--team-color);
      border: none;
      box-shadow: 4px 4px 6px #999;
    }

    a:hover {
      opacity: 0.8;
    }

    a:active {
      background-color: #dbdbdb;
      box-shadow: 2px 2px 3px #666;
      transform: translateY(4px);
    }
  }

  .fcg-button-container:hover {
    opacity: revert;
  }

  .fcg-button-container:active {
    background-color: revert;
    box-shadow: revert;
    transform: revert;
  }
}

