#globalnav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    display: block;
    margin: 0;
    width: 100%;
    min-width: 320px;
    height: 48px;
    background: white;
    //opacity: 0.9;

    .globalnav-content {
        width: 100%;
        position: absolute;
        top: 0;
    }

    .globalnav-list {
        padding-inline-start: 0;
        margin: 0 -9px;
        display: flex;
        position: relative;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    .globalnav-item-logo {
        flex-grow: 1;
    }

    .globalnav-link {
        font-size: 15px;
        display: flex;
        align-items: center;
        color: black;
        position: relative;
        z-index: 1;
        padding: 0 9px;
        height: 48px;
    }

    .globalnav-link-logo {
        padding: 0 16px;
    }

    .globalnav-image {
        height: 30px;
    }

    .nav-line {
        height: 0.325em;
    }
}
