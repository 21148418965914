$breakpoint-desktop: 900px;
:root {
    --team-color: #3ca42c
}

html {
    background-attachment: fixed;
    font-family: 'Roboto', sans-serif;
    color: black;
    height: 100%;
}

body {
    margin: 0;
}

nav {
    display: flex;
    justify-content: flex-end;
}

h1.lighterHeader {
    color: white;
}

h2 {
    font-size: 2.3em;
}

p.strong {
    font-size: 1.7em;
}

fieldset {
    margin: 0;
    border-width: 0;
}

ul {
    list-style: none;
}

h3 {
    font-size: 1.5em;
    color: #9b93c9;
}

p {
    font-size: 20px;
}

a {
    color: black;
    font-size: 1.5em;
    text-decoration: none;
    transition: all 100ms;
}

.verticalLeftStyle {
    text-align: right;
}

.sign-up {
    color: #c18d3b;
}

@media (max-width: 959px) {
    .verticalLeftStyle {
        text-align: left;
    }
}

@media (min-width: $breakpoint-desktop) {
}
