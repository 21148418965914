$breakpoint-desktop: 900px;

h1 {
    color: white;
}

h2 {
    color: initial;
}

h3 {
    color: initial;
}

.teamShelbyHeader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.teamShelbyBlurbContainer {
    margin: 2rem;
    position: relative;
}

.teamShelbyBlurb {
    max-width: 60%;
    margin: auto;
}

.teamShelbyList {
    width: fit-content;
    text-align: initial;
    margin: auto;
    list-style: initial;
    &:last-child {
        margin-bottom: 3rem;
    }
}

@media (min-width: $breakpoint-desktop) {

}
