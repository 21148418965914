$breakpoint-desktop: 900px;

.newStyling {
  background-color: white;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  color: black;
  height: 100%;
  padding-bottom: 30px;
}

.header-container {
  position: relative;
  text-align: center;
  display: flex;
}

.artisanTitleText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%
}

.homepageBanner {
  height: 20em;
  width: 100%;
}

.homepageGreenDecoration2 {
  margin-bottom: -10em;
}

.programContainer {
  justify-items: center;
  align-items: center;
  display: grid;
  align-self: center;
  grid-template-areas:
        'sectionHeader'
        'jobShadowingImage'
        'jobShadowingTitle'
        'jobShadowingDescription'
        'jobShadowingButton'
        'teamShelbyImage'
        'teamShelbyTitle'
        'teamShelbyDescription'
        'teamShelbyButton'
        'settImage'
        'settTitle'
        'settDescription'
        'settButton';
}

.responsiveSpacer {
  display: inline-block;
  height: 50px;
}

.techImageContainer {
  margin: 0 20%;
  display: grid;
  grid-template-areas: '. . .';
  border-style: solid;
  border-width: 10px;
  border-color: var(--team-color);
  border-radius: 40px;
  text-align: center;
}

.productImageContainer {
  margin: 0 20% 0 20%;
  display: grid;
  grid-template-areas: '. .';
}

.textColumnFormat {
  text-align: center;
  margin: 20px 25%;
}

a.columnFormat {
  background-color: var(--team-color);
  width: 60%;
  min-width: 243px;
  height: 40px;
  align-items: center;
  font-size: large;
  border-radius: 101px;
  border: none;
  outline: none;
  box-shadow: 4px 4px 6px #999;
  cursor: pointer;
  color: black;
  display: grid;
  margin-bottom: 1em;
}

a.columnFormat:hover {
  opacity: 0.8;
}

a.columnFormat:active {
  background-color: #dbdbdb;
  box-shadow: 2px 2px 3px #666;
  transform: translateY(4px);
}

.jobShadowingImage img {
  max-width: 100%;
  max-height: 180px;
}

.teamShelbyImage img {
  max-width: 100%;
  max-height: 180px;
  position: relative;
  z-index: 0;
}

.settImage img {
  padding-top: 3vw;
}

.sectionHeader {
  grid-area: sectionHeader;
  position: relative;
  color: black;
  margin-bottom: 0;
}

.techImageColumn {
  float: left;
  width: 100%;
  padding: 2vw;
  box-sizing: border-box;
}

.productImageBlock {
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 3px;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
}

.productImageBlock:hover {
  transition: 0.5s ease;
  transform: scale(1.1);
  z-index: 99;
}

.productImageBlock:hover .overlay {
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0 0 5px 5px black;
}

.productImageBlock .text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.jobShadowingImage {
  grid-area: jobShadowingImage;
}

.settImage {
  grid-area: settImage;
}

.jobShadowingTitle {
  grid-area: jobShadowingTitle;
  color: black;
}

.teamShelbyTitle {
  grid-area: teamShelbyTitle;
  color: black;
}

.settTitle {
  grid-area: settTitle;
  color: black;
}

.jobShadowingDescription {
  grid-area: jobShadowingDescription;
}

.settDescription {
  grid-area: settDescription;
}

.jobShadowingButton {
  grid-area: jobShadowingButton;
  position: relative;
  z-index: 1;
}

.settButton {
  grid-area: settButton;
}

@media (min-width: $breakpoint-desktop) {
  .programContainer {
    margin: 0 10%;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas:
            'sectionHeader sectionHeader sectionHeader'
            'jobShadowingImage teamShelbyImage settImage'
            'jobShadowingTitle teamShelbyTitle settTitle'
            'jobShadowingDescription teamShelbyDescription settDescription'
            'jobShadowingButton teamShelbyButton settButton';
  }

  .responsiveSpacer {
    display: none;
    height: 0;
  }

  .productImageContainer {
    grid-template-areas: '. . .';
  }

  .techImageContainer {
    grid-template-areas: '. . . .';
  }

  .textColumnFormat {
    margin-right: 10px;
    margin-left: 10px;
  }
}
